import React, { useState } from "react";
import { Link } from "gatsby"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import HeroBanner from "../components/home/heroBanner/HeroBanner"
import { Container } from "reactstrap"
import { Grommet, Table, TableHeader, TableBody, Text, TableRow, TableCell, Box, DataTable, Heading } from "grommet";
import styled from "styled-components";
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"

const StyledTable = styled(Table)`
  width: 100%;
  font-size: 14px;
`;

const StyledTableHead = styled(TableCell)`
  background: #daeef3;
  font-weight: 600;
  width: 50%;
`

let reschedule = () => {
    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            <TitleBannerEnquiry
                title="Terms & Conditions"
                description=""
                withOnlyDescription
                hasPlainBanner
            />

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4"><br />
                    <h2 id="reschedule-policy" className="trainingTitle">Re-scheduling Policy</h2>
                    <p className="trainingContent">
                        <br />
                        • Re-scheduling is only for learners with a confirmed enrolment.
                        <br /><br />
                        • Request for re-scheduling shall be submitted via our Re-scheduling Form at least <strong>10 working days</strong> before course start date.
                        <br /><br />
                        • Subject to meeting the above stipulated timeline
                        <br />
                        <div style={{ marginLeft: "20px" }}>
                            <p>✓ 1st reschedule shall be arranged at no charge.</p>
                            <p>✓ Subsequent re-scheduling is subject to a $50 administrative charge per request.</p>
                        </div><br />
                        • Re-scheduling request submitted with less than 10 working days before course start date is permitted for learners with valid reasons and supporting documents i.e. medical reasons.
                        <br /><br />
                        • Learners will be notified of the re-scheduling within 3 working days of submitting the re-schedule request.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>
            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4"><br /><br />
                    <h2 id="withdrawal-policy" className="trainingTitle">Withdrawal/Refund Policy</h2>
                    <p className="trainingContent"><br />
                        •	Withdrawal is only for learners with a confirmed enrolment.
                        <br /><br />
                        •	Learners may submit your request for withdrawal by submitting a Withdrawal Form.
                        <br /><br />
                        •	All withdrawal and refund requests are subject to the following conditions:
                    </p><br />
                    <p className="trainingContent">
                        <Container className="pt-4">
                            <div className="text-center mb-2">
                                <StyledTable >
                                    <TableHeader>
                                        <TableRow>
                                            <StyledTableHead scope="col" border="all" align="center">
                                                <strong> When written notice of cancellation or withdrawal is received</strong>
                                            </StyledTableHead>
                                            <StyledTableHead scope="col" border="all" align="center">
                                                <strong>  Refund policy</strong>
                                            </StyledTableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell scope="row" border="all" align="center">
                                                More than 10 working days before course commencement date
                                            </TableCell>
                                            <TableCell border="all" align="center">
                                                Full refund of course fees paid
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row" border="all" align="center">
                                                4-10 working days before course commencement date
                                            </TableCell>
                                            <TableCell border="all" align="center">
                                                Refund of course fees paid with an admin charge of $100
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell scope="row" border="all" align="center">
                                                3 or less working days before course commencement date
                                            </TableCell>
                                            <TableCell border="all" align="center">
                                                No refund
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </StyledTable>
                            </div><br />
                            •	Learners will be notified of the outcome via email <strong>within 7 working days</strong> of submitting the request.
                            <br /><br />
                        </Container>
                    </p><br /><br />
                </Container>
            </Container>
            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4"><br /><br />
                    <h2 id="appeal-policy" className="trainingTitle">Appeal Policy and Procedure</h2>
                    <p className="trainingContent"><br />
                        •	Appeal is only for learners who have completed our training course and assessment, and hold a valid assessment result.
                        <br /><br />
                        •	Candidate can appeal against the result of the assessment and submit an Appeal Form  <strong> within 3 working days</strong>  after the assessment result has been made known to the candidate.
                        <br /><br />
                        •	Candidate may submit only one appeal form per course.
                        <br /><br />
                        •	There will be a <strong> non-refundable charge of $50.00</strong>  (excluding GST) per appeal.
                        <br /><br />
                        •	Candidate will be informed of the outcome of the appeal via email <strong> within 5 working days</strong>  from the submission of his/her appeal.
                        <br /><br />
                        •	If a re-assessment is approved, the re-assessment to be conducted <strong> within 3 working days</strong>  from the notification to the Candidate.
                        <br /><br />
                        •	The decision of the Appeal Panel is final.
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>
            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4"><br /><br />
                    <h2 id="feedback" className="trainingTitle">Feedback/Complaint</h2>
                    <p className="trainingContent"><br />
                        •	Learner/Candidate may share feedback(s) or lodge a complaint through <a href="mailto:training@aia.edu.sg">training@aia.edu.sg</a>
                        <br /><br />
                        •	Learner/Candidate will be notified of the outcome of complaint via email <strong> within 7 working days</strong> .
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>
            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4"><br /><br />
                    <h2 id="personal-data" className="trainingTitle">Personal Data Protection Act</h2>
                    <p className="trainingContent"><br />
                        •	We require your full FIN/NRIC number in order to check with SSG as to your full training grant entitlement as well as apply for the applicable grants on your behalf. Rest assured that your personal details will be safeguarded in accordance with the Personal Data Protect Act (PDPA).
                        <br /><br />
                    </p><br /><br />
                </Container>
            </Container>
        </>
    )
}
export default reschedule